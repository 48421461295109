import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

const styles = theme => ({
  block: {
    marginLeft: "50%",
    textDecoration: "none",
    textAlign: "left",
    color: "#ffffff"
  },
  left: {
    float: "left!important",
    display: "block",
    textDecoration: "none"
  },
  right: {
    textAlign: "right",
    marginRight: "75px",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      textAlign: "center"
    }
  },
  footer: {
    bottom: "0",
    height: "72px",
    zIndex: 4,
    position: "relative",
    backgroundColor: "rgba(255, 255, 255, 0)",
    color: "#ffffff !important",
    fontSize: "16px"
  },
  container: {
    zIndex: 3,
    marginRight: "auto",
    marginLeft: "auto",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    },
    position: "relative"
  },
  containerFluid: {
    zIndex: 3,
    marginRight: "auto",
    marginLeft: "auto",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    },
    padding: 0,
    margin: 0,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    width: "100%"
  },
  a: {
    color: theme.palette.warning.main,
    backgroundColor: "transparent",
    textDecoration: "none"
  }
});

const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <div className={classes.containerFluid}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Hidden smDown>
              <p className={classes.left}>
                <a
                  href="https://zaggo.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.block}
                >
                  ABOUT
                </a>
                <Link to="#blog" className={classes.block}>
                  BLOG
                </Link>
              </p>
            </Hidden>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <p className={classes.right}>
              &copy; {1900 + new Date().getYear()}{" "}
              <a
                href="https://zaggo.io"
                className={classes.a}
                target="_blank"
                rel="noopener noreferrer"
              >
                Zaggo
              </a>
              . Todos los derechos reservados.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  );
};

export default Footer;
