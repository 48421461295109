import React from "react";
import Typography from "@material-ui/core/Typography";
import finishedIcon from "assets/icons/finishedIcon.svg";
import Button from "components/CustomButton/Button.js";

export default function FinishedPurchase({ setStep }) {
  return (
    <>
      <Typography variant="h4">
        ¡El dinero va camino a tu cuenta! Pronto recibirás un correo de nuestro
        banco.
      </Typography>
      <img
        src={finishedIcon}
        alt="FinalizarIcon"
        style={{ marginTop: "30px" }}
      />
      <Button
        style={{ width: "100%" }}
        color="rose"
        block
        onClick={() => setStep(1)}
      >
        Hacer otra venta
      </Button>
    </>
  );
}
