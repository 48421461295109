import React, { useState, useContext, createContext, useEffect } from "react";
import { usePrices } from "context/PricesContext";

export const OfferContext = createContext(null);

export const OfferProvider = ({ children }) => {
  const offerHook = useState();

  return (
    <OfferContext.Provider value={offerHook}>{children}</OfferContext.Provider>
  );
};

export function useOffer() {
  const [quantity, setQuantity] = useContext(OfferContext);
  const [purchaseAmount, setPurchaseAmount] = useState(null);
  const { prices, loading } = usePrices();

  useEffect(() => {
    if (!loading) {
      const currentPrice = prices.zaggo_price_offer;
      setPurchaseAmount(quantity * currentPrice);
    }
  }, [quantity, prices, loading]);

  return { quantity, setQuantity, prices, purchaseAmount };
}
