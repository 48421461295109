import React from "react";
import OfferMaker from "corecomponents/OfferMaker/OfferMaker.js";
import Button from "components/CustomButton/Button.js";
import api from "api/api";
import { useOffer } from "context/OfferContext";
import { useUser } from "context/UserContext";

export default function AcceptOffer({
  setStep,
  step,
  setAcceptedOffer,
  bankInfo
}) {
  const { quantity, purchaseAmount, prices } = useOffer();
  const { user } = useUser();

  const createPurchase = async () => {
    const { id } = prices;

    console.log(bankInfo);

    const response = await api.post(
      "purchases/",
      {
        btc_amount: quantity,
        cop_amount: purchaseAmount,
        price_offer: id,
        customer: user.user.customer.uuid,
        bank_info: bankInfo.id
      },
      {
        headers: {
          Authorization: `Token ${user.key}`
        }
      }
    );

    setAcceptedOffer(response.data);

    if (response.status === 201) {
      setStep(step + 1);
    }
  };

  return (
    <>
      <OfferMaker />

      <Button
        onClick={createPurchase}
        color="rose"
        block
        style={{ zIndex: "900 !important" }}
        disabled={!(quantity > 0)}
      >
        ¡Acepto este monto!
      </Button>
    </>
  );
}
