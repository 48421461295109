import React, { useState, useEffect, useCallback } from "react";
import api from "api/api";
import { useUser } from "context/UserContext";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import BankIcon from "@material-ui/icons/AccountBalance";
import Typography from "@material-ui/core/Typography";
import CreateBankInfoDialog from "views/Sell/SellSteps/ConfirmBank/CreateBankInfoDialog";
import Link from "@material-ui/core/Link";

import { makeStyles } from "@material-ui/core/styles";

const styles = theme => ({
  transactionsTitle: { textAlign: "left" },
  newBankLink: {
    float: "left",
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

const useStyles = makeStyles(styles);

export default function ChooseBank({ setBankInfo }) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useUser();
  const [banks, setBanks] = useState(null);
  const [checked, setChecked] = useState(0);

  const handleToggle = index => () => {
    setChecked(index);
    setBankInfo(banks[index]);
  };

  const fetchBanks = useCallback(async () => {
    const response = await api.get(
      `${process.env.REACT_APP_API_URL}onboarding/bankinformation/?customer=${user.user.customer.uuid}`
    );
    setBanks(response.data);
  }, [user, setBanks]);

  useEffect(() => {
    if (banks) {
      setBankInfo(banks[0]);
    }
  }, [banks, setBankInfo]);

  useEffect(() => {
    if (user) {
      fetchBanks();
    }
  }, [user, fetchBanks, openDialog]);

  if (!banks) {
    return "Cargando...";
  }

  return (
    <>
      <Typography
        className={classes.transactionsTitle}
        variant="h5"
        gutterBottom
      >
        Selecciona una cuenta de banco para la transacción:
      </Typography>
      <List>
        {banks.length > 0 ? (
          <>
            {banks.map((bank, i) => (
              <ListItem
                key={i}
                role={undefined}
                dense
                button
                onClick={handleToggle(i)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <BankIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  id={i}
                  primary={bank.bank_name}
                  secondary={`No. ${bank.bank_account_number} (${bank.bank_account_type_name})`}
                />
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={i === checked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": i }}
                  />
                </ListItemIcon>
              </ListItem>
            ))}
          </>
        ) : (
          <Typography variant="h3" gutterBottom>
            ¡No tienes ninguna cuenta de banco registrada!
          </Typography>
        )}
      </List>
      <Link
        variant="body1"
        onClick={() => setOpenDialog(true)}
        color="primary"
        className={classes.newBankLink}
      >
        Agregar nuevo banco
      </Link>
      <CreateBankInfoDialog
        onClose={() => setOpenDialog(false)}
        open={openDialog}
      />
    </>
  );
}
