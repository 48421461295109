import React from "react";
import useSWR from "swr";
import Typography from "@material-ui/core/Typography";

import bitcoinIcon from "assets/icons/bitcoinAcceptedIcon.svg";
import { useUser } from "context/UserContext";
import fetcher from "shared/SWRFetcher";

export default function SendBTC({ setStep, step, acceptedOffer }) {
  const { user } = useUser();

  useSWR(
    user
      ? [
          `${process.env.REACT_APP_API_URL}purchases/${acceptedOffer.id}/`,
          {
            headers: {
              Authorization: `Token ${user.key}`
            }
          }
        ]
      : null,
    fetcher,
    {
      refreshInterval: 30000,
      onSuccess: (data, key, config) => {
        console.log(data);
        if (data.status === 2) {
          setStep(step + 1);
        }
      }
    }
  );

  return (
    <>
      <Typography variant="h6">
        Para completar el envío, por favor espera una confirmación.
      </Typography>
      <img src={bitcoinIcon} alt="BitcoinIcon" style={{ marginTop: "30px" }} />
      <Typography variant="body1">15 min aprox.</Typography>
    </>
  );
}
