import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/views/loginPageStyle.js";
import SellStepper from "views/Sell/SellStepper";
import CurrentStep from "views/Sell/CurrentStep";
import Hidden from "@material-ui/core/Hidden";
import { useUser } from "context/UserContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

const steps = [
  "Confirma tus datos bancarios para recibir COP.",
  "Dinos cuantos Bitcoins vas a vender y acepta nuestra oferta.",
  "Envía los Bitcoins a la dirección designada.",
  "Espera la primera confirmación de la transacción.",
  "Tu dinero debería estar efectivo en 24 horas hábiles (Transferencia ACH)."
];

export default function Sell() {
  const [step, setStep] = useState(1);
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [acceptedOffer, setAcceptedOffer] = useState(null);
  const [bankInfo, setBankInfo] = useState(null);
  const { user } = useUser();
  const history = useHistory();

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  console.log(user);

  const classes = useStyles();

  if (user.user.customer.status !== 4) {
    history.push("/auth/awaitingverification");
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={8}>
          <Card login className={classes[cardAnimaton]}>
            <CardBody style={{ textAlign: "center" }}>
              <Hidden smDown>
                <SellStepper steps={steps} currentStep={step} />
              </Hidden>
              <CurrentStep
                step={step}
                setStep={setStep}
                acceptedOffer={acceptedOffer}
                setAcceptedOffer={setAcceptedOffer}
                bankInfo={bankInfo}
                setBankInfo={setBankInfo}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
