import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";

const styles = theme => ({
  listItem: {
    float: "left",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      zIndex: "999",
      width: "100%",
      paddingRight: "15px"
    }
  }
});

const useStyles = makeStyles(styles);

export default function NavLinkWrapper({ children }) {
  const classes = useStyles();

  return <ListItem className={classes.listItem}>{children}</ListItem>;
}
