import React from "react";
import Button from "components/CustomButton/Button.js";
import ChooseBank from "views/Sell/SellSteps/ConfirmBank/ChooseBank";

export default function AcceptOffer({ setStep, step, setBankInfo, bankInfo }) {
  return (
    <>
      <ChooseBank setBankInfo={setBankInfo} bankInfo={bankInfo} />
      <Button
        onClick={() => setStep(step + 1)}
        color="rose"
        block
        style={{ zIndex: "900 !important" }}
      >
        Confirmar datos bancarios
      </Button>
    </>
  );
}
