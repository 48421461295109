import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/views/loginPageStyle.js";
import { useUser } from "context/UserContext";
import { useHistory } from "react-router-dom";
import api from "api/api";
import TransactionsTable from "views/Transactions/TransactionsTable";

const useStyles = makeStyles(styles);

export default function Transactions() {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [transactions, setTransactions] = useState(null);
  const { user } = useUser();
  const history = useHistory();

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const fetchTransactions = useCallback(async () => {
    const response = await api.get(
      `${process.env.REACT_APP_API_URL}purchases/?customer=${user.user.customer.uuid}`,
      {
        headers: {
          Authorization: `Token ${user.key}`
        }
      }
    );
    setTransactions(response.data);
  }, [user, setTransactions]);

  useEffect(() => {
    if (user) {
      fetchTransactions();
    }
  }, [user, fetchTransactions]);

  const classes = useStyles();

  if (user.user.customer.status !== 4) {
    history.push("/auth/awaitingverification");
  }

  return (
    <div className={classes.container}>
      <h2 className={classes.cardTitle}>Mis Transacciones</h2>
      <Card login className={classes[cardAnimaton]}>
        <CardBody style={{ textAlign: "center" }}>
          {transactions && <TransactionsTable transactions={transactions} />}
        </CardBody>
      </Card>
    </div>
  );
}
