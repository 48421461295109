const customInputStyle = theme => ({
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: theme.palette.text.dark + "!important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: theme.palette.primary.main
    },
    "& + p": {
      fontWeight: "300"
    }
  },
  underlineError: {
    "&:after": {
      borderColor: theme.palette.danger.main
    }
  },
  underlineSuccess: {
    "&:after": {
      borderColor: theme.palette.success.main
    }
  },
  labelRoot: {
    //...defaultFont,
    color: theme.palette.text.dark + " !important",
    fontWeight: "400",
    fontSize: "1.2rem",
    lineHeight: "1.42857",
    top: "5px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px"
    }
  },
  labelRootError: {
    color: theme.palette.danger.main + " !important"
  },
  labelRootSuccess: {
    color: theme.palette.success.main + " !important"
  },
  formControl: {
    margin: "0 0 4px 0",
    paddingTop: theme.spacing(3),
    position: "relative",
    verticalAlign: "unset"
  },
  input: {
    height: "unset",
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1"
    },
    "&::placeholder": {
      color: theme.palette.text.dark
    }
  },
  inputAdornmentIcon: {
    color: "rgba(0, 0, 0, 0.54)"
  }
});

export default customInputStyle;
