import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "components/Chip/Chip";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  }
}));

export default function PriceReference({ source, price }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h5">{source}</Typography>
      <Chip label={price} />
    </div>
  );
}
