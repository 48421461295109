import React from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/components/customInputStyle.js";
import InputAdornment from "@material-ui/core/InputAdornment";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `30px`
      }
    }
  }
})(TextField);

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    inputProps,
    error,
    //white,
    success,
    helperText,
    inputRef,
    name,
    select,
    options,
    selectValue,
    setSelectValue,
    adornmentEnd,
    icon
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });

  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  });

  const handleChange = event => {
    setSelectValue(event.target.value);
  };

  const adornment = icon && (
    <InputAdornment
      className={classes.inputAdornmentIcon}
      position={adornmentEnd ? "end" : "start"}
    >
      {icon}
    </InputAdornment>
  );

  const adornmentObject = adornmentEnd
    ? { endAdornment: adornment }
    : { startAdornment: adornment };

  return (
    <FormControl {...formControlProps} fullWidth className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          shrink
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <CustomTextField
        inputRef={inputRef}
        name={name}
        autoComplete="off"
        variant="outlined"
        select={select}
        value={select ? selectValue : undefined}
        onChange={select ? handleChange : undefined}
        inputProps={{ autoComplete: "off" }}
        InputProps={{ inputProps, ...adornmentObject }}
        id={id}
      >
        {select &&
          options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </CustomTextField>
      {helperText !== undefined ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node
};
