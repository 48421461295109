import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = theme => ({
  textCenter: {
    textAlign: "center"
  },
  container: {
    marginRight: "auto",
    marginLeft: "auto",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    },
    "@media (min-width: 576px)": {
      maxWidth: "540px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px"
    },
    position: "relative",
    zIndex: "3"
    // paddingTop: "23vh"
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0,0,0, 0.14), 0 6px 30px 5px rgba(0,0,0, 0.12), 0 8px 10px -5px rgba(0,0,0, 0.2)",
    marginBottom: "100px",
    padding: "20px 0px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  form: {
    padding: "0 20px",
    margin: "0 10%",
    position: "relative"
  },
  socialTitle: {
    fontSize: "18px"
  },
  terms: {
    color: theme.palette.primary.main,
    textDecoration: "underline"
  },
  cardTitle: {
    margin: "1.75rem 0 0.875rem",
    textDecoration: "none",
    fontWeight: "700",
    marginTop: ".625rem",
    textAlign: "center",
    color: "#ffffff"
  },
  cardHeader: {
    marginBottom: "20px"
  }
});

const useStyles = makeStyles(styles);

export default function Form({ onSubmit, children }) {
  const classes = useStyles();

  return (
    <form className={classes.root} onSubmit={onSubmit}>
      {children}
    </form>
  );
}
