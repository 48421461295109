import React from "react";
import Typography from "@material-ui/core/Typography";
import useSWR from "swr";
import qr from "assets/images/qr.png";
import { useOffer } from "context/OfferContext";
import { useUser } from "context/UserContext";
import fetcher from "shared/SWRFetcher";
import AddressContainer from "components/AddressContainer";

const WALLET_ADDRESS = "3KWTnGAaYJ6GEADp7T8fj1bY9H7SySYTWC";

export default function SendBTC({ setStep, step, acceptedOffer }) {
  const { quantity } = useOffer();
  const { user } = useUser();

  useSWR(
    user
      ? [
          `${process.env.REACT_APP_API_URL}purchases/${acceptedOffer.id}/`,
          {
            headers: {
              Authorization: `Token ${user.key}`
            }
          }
        ]
      : null,
    fetcher,
    {
      refreshInterval: 30000,
      onSuccess: (data, key, config) => {
        console.log(data);
        if (data.status === 1) {
          setStep(step + 1);
        }
      }
    }
  );

  return (
    <>
      <Typography variant="h6">
        Tienes 15 minutos para enviar {quantity} Bitcoins a esta dirección:
      </Typography>
      <br />
      {/* <Typography variant="body1">{WALLET_ADDRESS}</Typography> */}
      <AddressContainer address={WALLET_ADDRESS} />
      <img
        // onClick={() => setStep(4)}
        alt="QR"
        height="200"
        width="200"
        border="0"
        src={qr}
      />
    </>
  );
}
