import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  chip: {
    padding: theme.spacing(2.5),
    background: "white",
    color: theme.palette.primary.main
  }
}));

export default function Chips({ label }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Chip
        className={classes.chip}
        label={<Typography variant="h4">{label}</Typography>}
      />
    </div>
  );
}
