const registerPageStyle = theme => ({
  textCenter: {
    textAlign: "center"
  },
  container: {
    marginRight: "auto",
    marginLeft: "auto",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    },
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "90%"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "70%"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "55%"
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "40%"
    },
    position: "relative",
    zIndex: "3"
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0,0,0, 0.14), 0 6px 30px 5px rgba(0,0,0, 0.12), 0 8px 10px -5px rgba(0,0,0, 0.2)",
    marginBottom: "100px",
    padding: "20px 0px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  form: {
    padding: "0 20px",
    margin: "0 10%",
    position: "relative"
  },
  socialTitle: {
    fontSize: "18px"
  },
  cardTitle: {
    margin: "1.75rem 0 0.875rem",
    textDecoration: "none",
    fontWeight: "700",
    marginTop: ".625rem",
    textAlign: "center",
    color: "#ffffff"
  },
  cardHeader: {
    marginBottom: "20px"
  }
});

export default registerPageStyle;
