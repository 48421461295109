import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Grid } from "@material-ui/core";
import RegisterSteps from "./RegisterSteps";
import UserInformation from "./UserInformation";
import PhoneValidation from "./PasswordCreate";
import FinancialInformation from "./FinancialInformation";
import Hidden from "@material-ui/core/Hidden";
import styles from "assets/jss/views/registerPageStyle";

const useStyles = makeStyles(styles);

export default function RegisterPage() {
  const [step, setStep] = useState(1);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h2 className={classes.cardTitle}>Registro</h2>
      <Card className={classes.cardSignup}>
        <CardBody>
          <Grid container justify="center">
            <Hidden xsDown>
              <Grid item sm={12} md={6}>
                <RegisterSteps activeStep={step} />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
              {step === 1 ? (
                <UserInformation setStep={setStep} />
              ) : step === 2 ? (
                <PhoneValidation setStep={setStep} />
              ) : (
                <FinancialInformation setStep={setStep} />
              )}
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </div>
  );
}
