import tinycolor from "tinycolor2";

const primary = "#442BE1";
//const secondary = "#6ec2ef";
const secondary = "#63AED6";
const warning = "#efb74a";
const success = "#5BDC0B";
const info = "#1c2031";
const danger = "#ff373b";
const rose = "#DB00FF";
const text = "#7F8287";

const lightenRate = 5;
const darkenRate = 20;

export default {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString()
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF"
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString()
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString()
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString()
    },
    danger: {
      main: danger,
      light: tinycolor(danger)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(danger)
        .darken(darkenRate)
        .toHexString()
    },
    rose: {
      main: rose,
      light: tinycolor(rose)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(rose)
        .darken(darkenRate)
        .toHexString()
    },
    text: {
      main: "#97A7B1",
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
      subtitle: "#A3A6B4"
    },
    background: {
      main: "#F6F7FF",
      default: "#F6F7FF",
      light: "#F3F5FF"
    }
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
  },
  overrides: {
    MuiStepLabel: {
      active: {
        color: secondary + " !important"
      },
      label: {
        color: text
      },
      completed: {
        color: success + " !important"
      }
    },
    MuiStepConnector: {
      root: {
        "& $line": {
          borderWidth: "3px",
          borderColor: "#ffffff"
        },
        "& $lineVertical": {
          //borderWidth: "3px",
          marginLeft: "14px"
        },
        "& $lineHorizontal": {
          //borderWidth: "3px",
          marginTop: "14px",
          marginLeft: "8px",
          marginRight: "8px"
        }
      },
      vertical: {
        padding: 0
      },
      text: {
        fill: text
      },
      active: {
        "& $line": {
          borderColor: success
        },
        "& $lineVertical": {}
      },
      completed: {
        "& $line": {
          borderColor: success
        }
      },
      line: {}
    },
    MuiStepIcon: {
      root: {
        fill: "white",
        borderStyle: "solid",
        height: "56px",
        width: "56px",
        borderWidth: "3px",
        borderRadius: 200,
        borderColor: text,
        "&$completed": {
          borderColor: success,
          backgroundColor: success
        },
        "&$active": {
          borderColor: secondary,
          "& $text": {
            fill: secondary
          }
        }
      },
      text: {
        fill: text
      },
      active: {},
      completed: {}
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A"
      }
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
      }
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9"
      }
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white"
      }
    }
  }
};
