import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButton/Button.js";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import styles from "assets/jss/views/loginPageStyle.js";
import LocalBitcoinPricesSection from "views/Home/LocalBitcoinPricesSection";
import { formatCurrency } from "utils/functions";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles(styles);

export default function HomeLayout({ prices }) {
  const classes = useStyles();
  const { local_btc_buy, local_btc_sell, zaggo_price_offer } = prices;

  return (
    <Fade in={true}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={8}>
            <div style={{ textAlign: "center" }}>
              <Typography variant="h1">
                Te compramos el Bitcoin a {formatCurrency(zaggo_price_offer)}{" "}
                COP
              </Typography>
              <LocalBitcoinPricesSection
                local_btc_sell={local_btc_sell}
                local_btc_buy={local_btc_buy}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <div>
                  <Link style={{ textDecoration: "none" }} to="/auth/offer">
                    <Button color="rose" simple className={classes.sellButton}>
                      ¡Quiero vender Bitcoins!
                    </Button>
                  </Link>
                </div>
                <div>
                  <Link style={{ textDecoration: "none" }} to="/auth/offer">
                    <Button
                      size="lg"
                      color="transparent"
                      classes={{
                        label: classes.label
                      }}
                      disableRipple
                    >
                      <PlayCircleOutlineIcon /> Ver video explicativo
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Fade>
  );
}
