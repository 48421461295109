import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    paddingRight: "15%"
  }
}));

function getSteps(activeStep) {
  return [
    activeStep > 1 ? "Registro iniciado" : "Iniciemos tu registro",
    activeStep > 2 ? "Contraseña creada" : "Crea tu contraseña",
    "Información financiera"
  ];
}

export default function VerticalLinearStepper(props) {
  const classes = useStyles();
  const { activeStep } = props;
  const steps = getSteps(activeStep);

  return (
    <div className={classes.root}>
      <Stepper
        orientation="vertical"
        // connector={<QontoConnector />}
        classes={{
          root: classes.stepper
        }}
        activeStep={activeStep - 1}
      >
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>
                <h3>{label}</h3>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
