import React, { useState } from "react";
import useSWR from "swr";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { useUser } from "context/UserContext";
import fetcher from "shared/SWRFetcher";

import styles from "assets/jss/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function Offer() {
  const { user, setUser } = useUser();
  const history = useHistory();
  const [message, setMessage] = useState(
    "Estamos verificando tu información personal. Este proceso puede demorarse hasta 10 minutos"
  );

  const { error } = useSWR(
    user
      ? [
          `${process.env.REACT_APP_API_URL}onboarding/customers/${user.user.customer.uuid}/`,
          {
            headers: {
              Authorization: `Token ${user.key}`
            }
          }
        ]
      : null,
    fetcher,
    {
      refreshInterval: 15000,
      errorRetryInterval: 10000,
      onSuccess: (data, key, config) => {
        if (user.user.customer.status !== data.status) {
          let newUser = user;
          newUser.user.customer = data;
          setUser(newUser);
        }

        if (data.status === 4) {
          history.push("/sell");
        } else if (data.status === 5) {
          setMessage(
            "Tu información tendrá que ser validada manualmente y serás notificado en máximo 24 horas."
          );
        }
      }
    }
  );

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 1000);

  const classes = useStyles();

  if (error) return <div>Error! Por favor recarga la página.</div>;

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={8}>
          <Card login className={classes[cardAnimaton]}>
            <CardBody style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <img
                  src={
                    "https://img.icons8.com/nolan/128/electronic-identity-card.png"
                  }
                  alt="AWAITING_VERIFICATION"
                  style={{
                    width: "30%"
                  }}
                />
              </div>
              <Typography variant="h5">{message}</Typography>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
