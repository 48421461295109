import React from "react";
import MainLayout from "layouts/MainLayout";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import themes, { overrides } from "assets/jss/index";
import { UserProvider } from "context/UserContext";
import { PricesProvider } from "context/PricesContext";
import { OfferProvider } from "context/OfferContext";
import "assets/font.css";

const theme = createMuiTheme({ ...themes.default, ...overrides });

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <UserProvider>
        <PricesProvider>
          <OfferProvider>
            <MainLayout />
          </OfferProvider>
        </PricesProvider>
      </UserProvider>
    </MuiThemeProvider>
  );
};

export default App;
