import React, { useState } from "react";
import { useForm } from "react-hook-form";
import api from "api/api";
import { Grid } from "@material-ui/core";
import Button from "components/CustomButton/Button.js";
import LockIcon from "@material-ui/icons/Lock";
import CustomInput from "components/CustomInput/CustomInput.js";
import { useUser } from "context/UserContext";
import Form from "components/Form/Form";

export default function PasswordCreate(props) {
  const { setStep } = props;
  const { register, handleSubmit } = useForm();

  const [differentPasswords, setDifferentPasswords] = useState(false);

  const { user } = useUser();

  const onSubmit = async data => {
    console.log(data);

    const { new_password1, new_password2 } = data;

    if (new_password1 === new_password2) {
      setDifferentPasswords(false);

      const response = await api.post(
        "rest-auth/password/change/",
        {
          old_password: "123456789",
          new_password1,
          new_password2
        },
        {
          headers: {
            Authorization: `Token ${user.key}`
          }
        }
      );

      if (response.status === 200) {
        setStep(3);
      }
    } else {
      setDifferentPasswords(true);
    }
  };

  return (
    <Grid container justify="center" style={{ height: "100%" }}>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img
            src={"https://img.icons8.com/nolan/128/privacy.png"}
            alt="phoneValidation"
            style={{
              width: "30%"
            }}
          />
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            labelText="Contraseña"
            inputRef={register}
            name={"new_password1"}
            inputProps={{
              type: "password"
            }}
            icon={<LockIcon />}
          />
          <CustomInput
            labelText="Confirmar contraseña"
            inputRef={register}
            name={"new_password2"}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "password"
            }}
            icon={<LockIcon />}
          />
          {differentPasswords && (
            <div>Las contraseñas ingresadas no coinciden!</div>
          )}
          <Button
            style={{ width: "100%" }}
            color="rose"
            onClick={handleSubmit(onSubmit)}
          >
            Crear contraseña
          </Button>
        </Form>
      </Grid>
    </Grid>
  );
}
