import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    background: theme.palette.primary.main,
    color: "#ffffff",
    borderRadius: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    color: "#ffffff"
  }
});

const useStyles = makeStyles(styles);

export default function AddressContainer({ address }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body1">{address}</Typography>
      <Tooltip title="Copiar">
        <IconButton
          aria-label="copy"
          className={classes.button}
          onClick={() => {
            navigator.clipboard.writeText(address);
          }}
        >
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}
