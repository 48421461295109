import React from "react";
import NavLinkWrapper from "corecomponents/AuthNavbar/NavLinkWrapper";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButton/Button";
import useActiveRoute from "corecomponents/AuthNavbar/useActiveRoute";
import { fade } from "@material-ui/core/styles/colorManipulator";

const styles = theme => ({
  navLink: {
    margin: "0 5px",
    paddingTop: "15px",
    paddingBottom: "15px",
    fontWeight: "500",
    fontSize: "1rem",
    textDecoration: "none"
  },
  buttonActive: {
    "&,&:focus,&:visited": {
      backgroundColor: fade(theme.palette.primary.main, 0.4),
      color: "#ffffff"
    }
  }
});

const useStyles = makeStyles(styles);

const SELL_ROUTE = "/sell";

export default function RegisterButton() {
  const classes = useStyles();
  const active = useActiveRoute(SELL_ROUTE);

  return (
    <NavLinkWrapper>
      <NavLink className={classes.navLink} to={SELL_ROUTE}>
        <Button className={active ? classes.buttonActive : ""} color={"white"}>
          ¡Vende yá!
        </Button>
      </NavLink>
    </NavLinkWrapper>
  );
}
