import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import api from "api/api";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Phone from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import Checkbox from "components/Checkbox/Checkbox";
import Button from "components/CustomButton/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Link from "@material-ui/core/Link";
import TermsAndConditionsDialog from "components/TermsAndConditionsDialog";
import { useUser } from "context/UserContext";
import Form from "components/Form/Form";

const idOptions = [
  { label: "C.C", value: 1 },
  { label: "C.E", value: 2 }
  //{ label: "Pasaporte", value: 3 }
];

export default function UserInformation(props) {
  const { setStep } = props;
  const { setUser } = useUser();
  const { register, handleSubmit } = useForm();
  const [idType, setIdType] = useState(idOptions[0].value);

  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  const onSubmit = async data => {
    data.id_number_type = idType;

    data.phone_number = "+57" + data.phone_number;
    data.id_number = data.id_number.replace(/\./g, "");

    const response = await api.post("onboarding/customers/", data);

    if (response.status === 201) {
      const loginData = {
        username: response.data.id_number,
        password: "123456789"
      };

      const loginResponse = await api.post("rest-auth/login/", loginData);
      if (loginResponse.status === 200) {
        setUser(loginResponse.data);
        setStep(2);
      }
    }
  };

  const handleClickTerms = () => {
    setOpen(true);
    setChecked(!checked);
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <CustomInput
              labelText="Tipo I.D."
              inputRef={register}
              select
              options={idOptions}
              name={"id_number_type"}
              selectValue={idType}
              setSelectValue={setIdType}
            />
          </Grid>
          <Grid item xs={8}>
            <CustomInput
              labelText="No. de Identificación"
              inputRef={register}
              name={"id_number"}
              icon={<AccountBoxIcon />}
            />
          </Grid>
        </Grid>
        <CustomInput
          labelText="Correo Electrónico"
          inputRef={register}
          name={"email"}
          icon={<EmailIcon />}
          inputProps={{ type: "email" }}
        />
        <CustomInput
          labelText="Celular"
          inputRef={register}
          name={"phone_number"}
          icon={<Phone />}
          inputProps={{ type: "tel" }}
        />
        <Checkbox
          message={
            <>
              He leído y acepto los{" "}
              <Link
                onClick={handleClickTerms}
                style={{ textDecoration: "underline", pointerEvents: "auto" }}
              >
                términos y condiciones
              </Link>
            </>
          }
          checked={checked}
          setChecked={setChecked}
        />
        <Button
          disabled={checked ? false : true}
          style={{ width: "100%" }}
          onClick={handleSubmit(onSubmit)}
          color="rose"
        >
          Continuar
        </Button>
      </Form>
      <TermsAndConditionsDialog open={open} setOpen={setOpen} />
    </>
  );
}
