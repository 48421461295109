import React, { useState, useContext, createContext, useEffect } from "react";
import Cookies from "js-cookie";

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  let user = null;

  try {
    user = JSON.parse(Cookies.get("__user"));
  } catch (error) {
    console.log("No user cookie");
  }

  const userHook = useState(user);

  return (
    <UserContext.Provider value={userHook}>{children}</UserContext.Provider>
  );
};

export function useUser() {
  const [user, setUser] = useContext(UserContext);

  useEffect(() => {
    Cookies.set("__user", user);
  }, [user]);

  const logOut = () => {
    Cookies.remove("__user");
    setUser(null);
  };

  return { user, setUser, logOut };
}
