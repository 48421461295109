import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// views
import Login from "views/Login/Login";
import Offer from "views/Offer/Offer";
import Register from "views/Register/Register";
import AwaitingVerification from "views/AwaitingVerification/AwaitingVerification";
import PrivateRoute from "components/PrivateRoute";

export default function AuthLayout() {
  // ref for the wrapper div

  // styles

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  return (
    <Switch>
      <Route path="/auth/login" component={Login} />
      <Route path="/auth/register" component={Register} />
      <Route path="/auth/offer" component={Offer} />
      {/* <Route path="/auth/sell" component={Sell} /> */}
      <PrivateRoute
        path="/auth/awaitingverification"
        component={AwaitingVerification}
        exact
      />
      <Redirect from="/auth" to="/auth/login" />
    </Switch>
  );
}
