import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PriceReference from "components/PriceReference/PriceReference";

import { formatCurrency } from "utils/functions";

const styles = theme => ({
  container: {
    display: "flex",
    marginTop: "30px",
    marginBottom: "30px",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  }
});

const useStyles = makeStyles(styles);

export default function HomeLayout({ local_btc_buy, local_btc_sell }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <PriceReference
        source="LocalBTC Sell"
        price={formatCurrency(local_btc_sell)}
      />
      <PriceReference
        source="LocalBTC Buy"
        price={formatCurrency(local_btc_buy)}
      />
    </div>
  );
}
