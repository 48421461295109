import React, { createRef } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

import AuthLayout from "./Auth.js";
import HomeContainer from "views/Home/HomeContainer";
import { makeStyles } from "@material-ui/core/styles";
import background from "assets/images/loginbackground.jpg";
import AuthNavbar from "corecomponents/AuthNavbar/AuthNavbar.js";
import Footer from "corecomponents/Footer/Footer.js";
import "assets/font.css";
import PrivateRoute from "components/PrivateRoute";
import Sell from "views/Sell/Sell";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Transactions from "views/Transactions/Transactions";

const styles = theme => ({
  wrapper: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0"
  },
  fullPage: {
    position: "relative",
    minHeight: "100vh",
    display: "flex!important",
    margin: "0",
    border: "0",
    color: "#ffffff",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   minHeight: "fit-content!important"
    // },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important"
    },
    "&:before": {
      backgroundImage:
        "linear-gradient(to top right, " +
        fade(theme.palette.primary.main, 0.8) +
        "," +
        fade(theme.palette.secondary.main, 0.8) +
        ")"
    },
    "&:before,&:after": {
      display: "block",
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "2"
    }
  }
});

const useStyles = makeStyles(styles);

const MainLayout = () => {
  const wrapper = createRef();
  const classes = useStyles();

  return (
    <BrowserRouter>
      <AuthNavbar />
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + background + ")" }}
        >
          <Switch>
            <Route exact path="/" component={HomeContainer} />
            <Route path="/auth" component={AuthLayout} />
            <PrivateRoute
              path="/transactions"
              component={Transactions}
              exact
              redirect="/auth/register"
            />
            <PrivateRoute
              path="/sell"
              component={Sell}
              exact
              redirect="/auth/register"
            />
            {/* <Route component={Error} /> */}
            <Redirect from="/" to="/" />
          </Switch>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default MainLayout;
