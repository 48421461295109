import React from "react";
import ConfirmBankInformation from "views/Sell/SellSteps/ConfirmBank/ConfirmBankInformation";
import AcceptOffer from "views/Sell/SellSteps/AceptOffer";
import SendBTC from "views/Sell/SellSteps/SendBTC";
import BTCConfirmations from "views/Sell/SellSteps/BTCConfirmations";
import FinishedPurchase from "views/Sell/SellSteps/FinishedPurchase";

export default function CurrentStep(props) {
  const { step } = props;
  switch (step) {
    case 1:
      return <ConfirmBankInformation {...props} />;

    case 2:
      return <AcceptOffer {...props} />;

    case 3:
      return <SendBTC {...props} />;

    case 4:
      return <BTCConfirmations {...props} />;

    case 5:
      return <FinishedPurchase {...props} />;

    default:
      return <div>Hubo un error inesperado.</div>;
  }
}
