import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import OfferMaker from "corecomponents/OfferMaker/OfferMaker.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButton/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function Offer() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={8}>
          <h2 className={classes.cardTitle}>Vender mis BTC</h2>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardBody>
                <OfferMaker />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Link
                  style={{ textDecoration: "none", width: "100%" }}
                  to="/sell"
                >
                  <Button style={{ width: "100%" }} color="rose" block>
                    ¡Vender estos BTC!
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
