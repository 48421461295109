import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useUser } from "context/UserContext";

const PrivateRoute = ({ path, exact, component, redirect }) => {
  const { user } = useUser();

  return user ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to={redirect ? redirect : "/auth/login"} />
  );
};

export default PrivateRoute;
