import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { formatCurrency } from "utils/functions";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

export default function TransactionsTable({ transactions }) {
  if (!transactions || transactions.length === 0) {
    return (
      <Typography variant="h3" gutterBottom>
        ¡Todavía no tienes transacciones!
      </Typography>
    );
  }

  console.log(transactions[0]);

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <b>Cantidad (BTC)</b>
          </TableCell>
          <TableCell align="right">
            <b>Monto (COP)</b>
          </TableCell>
          <TableCell align="right">
            <b>Precio COP/BTC</b>
          </TableCell>
          <TableCell align="right">
            <b>Estado</b>
          </TableCell>
          <TableCell align="right">
            <b>Fecha de confirmación</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map(row => (
          <TableRow key={row.name}>
            <TableCell component="th" scope="row">
              {row.btc_amount}
            </TableCell>
            <TableCell align="right">
              {formatCurrency(row.cop_amount)}
            </TableCell>
            <TableCell align="right">
              {formatCurrency(row.price_offer.zaggo_price_offer)}
            </TableCell>
            <TableCell align="right">{row.status_name}</TableCell>
            <TableCell align="right">
              {row.first_confirmation_date
                ? moment(row.first_confirmation_date).format("YYYY-MM-DD hh:mm")
                : "Sin confirmación"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
