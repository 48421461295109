import React from "react";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import NavLinkWrapper from "corecomponents/AuthNavbar/NavLinkWrapper";
import useActiveRoute from "corecomponents/AuthNavbar/useActiveRoute";
import Button from "components/CustomButton/Button";

const styles = theme => ({
  navLink: {
    margin: "5px",
    textDecoration: "none"
  },
  listItemIcon: {
    marginRight: "2px"
  },
  buttonActive: {
    backgroundColor: fade(theme.palette.primary.main, 0.4)
  }
});

const useStyles = makeStyles(styles);

export default function AuthNavLink({ label, route, icon, onClick }) {
  const classes = useStyles();
  const activeRoute = useActiveRoute(route);

  return (
    <NavLinkWrapper>
      <NavLink
        to={route}
        className={classes.navLink}
        onClick={onClick ? onClick : () => {}}
      >
        <Button
          className={activeRoute ? classes.buttonActive : ""}
          color={"transparent"}
        >
          <div className={classes.listItemIcon}>{icon}</div>
          <div>{label}</div>
        </Button>
      </NavLink>
    </NavLinkWrapper>
  );
}
