import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { formatCurrency } from "utils/functions";
import { useOffer } from "context/OfferContext";
import Grid from "@material-ui/core/Grid";
import { useUser } from "context/UserContext";
import CustomInput from "components/CustomInput/CustomInput.js";
import { loadCSS } from "fg-loadcss";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    textAlign: "center"
  },
  offer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main
  },
  inputContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(1)
  }
}));

export default function OfferMaker() {
  const { quantity, setQuantity, purchaseAmount, prices } = useOffer();
  const { user } = useUser();

  const classes = useStyles();

  useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }, []);

  return (
    <div className={classes.root}>
      {prices && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              ¿Cúanto quieres vender?
            </Typography>
            <Typography variant="h5">
              Precio actual:&nbsp;{formatCurrency(prices.zaggo_price_offer)}
              &nbsp;COP&nbsp;/&nbsp;BTC
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.inputContainer}>
            <div>
              <CustomInput
                labelText="Cantidad de BTC..."
                icon={<Icon className={"fab fa-bitcoin"} />}
                adornmentEnd
                inputProps={{
                  type: "number",
                  onChange: e => {
                    setQuantity(e.target.value);
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      )}
      {quantity > 0 && (
        <>
          <Typography variant="h6">
            Si compras {!user ? "ya*" : "ya"} podemos ofrecerte
          </Typography>
          <Typography className={classes.offer} variant="h4">
            <b>{formatCurrency(purchaseAmount)}&nbsp;COP</b>
          </Typography>
        </>
      )}
      {!user && (
        <Typography variant="subtitle1">
          *Si esta es tu primera venta con nosotros, puede que el monto fluctúe
          mientras tomamos tus datos
        </Typography>
      )}
    </div>
  );
}
