import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import ZaggoTransparent from "assets/icons/logo.svg";
import Button from "components/CustomButton/Button";
import NavLinkList from "corecomponents/AuthNavbar/NavLinkList";

const styles = theme => ({
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    border: "0",
    padding: "10px 0",
    minHeight: "50px",
    display: "block"
  },
  container: {
    display: "flex",
    margin: "0 75px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      margin: 0
    }
  },
  flex: {
    flex: 1
  },
  logo: {
    height: "50px"
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    boxShadow:
      "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    width: 260,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
    "&:before,&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      top: "0"
    },
    "&:after": {
      background: "#000000",
      opacity: ".8"
    }
  }
});

const useStyles = makeStyles(styles);

export default function AuthNavbar() {
  const [open, setOpen] = useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            <Button href="#" color="transparent">
              <img
                className={classes.logo}
                src={ZaggoTransparent}
                alt="ZAGGO"
              />
            </Button>
          </div>
        </Hidden>
        <Hidden smDown>
          <NavLinkList />
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={open}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true
            }}
          >
            <NavLinkList drawer />
          </Drawer>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}
