import React, { useState, useContext, createContext, useEffect } from "react";
import api from "api/api";

export const PricesContext = createContext(null);

export const PricesProvider = ({ children }) => {
  const pricesHook = useState();

  return (
    <PricesContext.Provider value={pricesHook}>
      {children}
    </PricesContext.Provider>
  );
};

export function usePrices() {
  const [prices, setPrices] = useContext(PricesContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!prices) {
      (async () => {
        const pricesResponse = await api.get("purchases/latestprices/");

        setPrices(pricesResponse.data);
        setLoading(false);
      })();
    }
  }, [prices, setPrices]);

  useEffect(() => {
    if (prices) {
      setLoading(false);
    }
  }, [prices, setPrices]);

  return { prices, loading };
}
