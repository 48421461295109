import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function CustomCheckbox(props) {
  const { message, name, checked, setChecked } = props;

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <FormControlLabel
      label={message}
      style={{ pointerEvents: "none" }}
      control={
        <Checkbox
          style={{ pointerEvents: "auto" }}
          checked={checked}
          onChange={handleChange}
          name={name}
        />
      }
    />
  );
}
