import React, { useState } from "react";
import { useUser } from "context/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Face from "@material-ui/icons/Face";
import LockIcon from "@material-ui/icons/Lock";
import api from "api/api";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButton/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForm } from "react-hook-form";
import styles from "assets/jss/views/loginPageStyle.js";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(styles);

export default function Login() {
  const { register, handleSubmit } = useForm();
  const { setUser } = useUser();
  const history = useHistory();
  const [loginError, setLoginError] = useState(false);

  const onSubmit = async data => {
    console.log(data);

    const { idNumber, password } = data;

    try {
      const response = await api.post(`rest-auth/login/`, {
        username: idNumber,
        password
      });

      console.log(response);

      if (response.status === 200) {
        setUser(response.data);
        if (response.data.user.customer.status === 4) {
          history.push("/sell");
        } else {
          console.log("else");
          history.push("/auth/awaitingverification");
        }
      }
    } catch (error) {
      console.log("wrong credentials");
      setLoginError(true);
    }
  };

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={8}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              {/* <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
                style={{ boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)" }}
              >
                <h2 className={classes.cardTitle}>Ingresar</h2>
              </CardHeader> */}
              <CardBody>
                <CustomInput
                  inputRef={register}
                  name={"idNumber"}
                  labelText="N. de Cédula"
                  id="firstname"
                  adornmentEnd
                  icon={<Face />}
                />
                <CustomInput
                  inputRef={register}
                  name={"password"}
                  labelText="Contraseña"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "password"
                  }}
                  adornmentEnd
                  icon={<LockIcon />}
                />
                {loginError && (
                  <Typography>
                    Hubo un error al ingresar. Por favor vuelve a intentarlo.
                  </Typography>
                )}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="rose" block onClick={handleSubmit(onSubmit)}>
                  Ingresar
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
