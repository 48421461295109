import defaultTheme from "./default";
import "../font.css";

export default {
  default: defaultTheme
};

export const overrides = {
  MuiStepIcon: {
    root: {
      "&$completed": {
        color: "pink"
      },
      "&$active": {
        color: "red"
      }
    },
    active: {},
    completed: {}
  },
  typography: {
    fontFamily: [
      '"Shentox-Regular"',
      '"Roboto"',
      "-apple-system",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Roboto Emoji"',
      '"Roboto Symbol"'
    ].join(","),
    h1: {
      fontSize: "3rem"
    },
    h2: {
      fontSize: "2rem"
    },
    h3: {
      fontSize: "1.64rem"
    },
    h4: {
      fontSize: "1.5rem"
    },
    h5: {
      fontSize: "1.285rem"
    },
    h6: {
      fontSize: "1.142rem"
    }
  }
};
