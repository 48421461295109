import React from "react";
import List from "@material-ui/core/List";
import Home from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Fingerprint from "@material-ui/icons/Fingerprint";
import { makeStyles } from "@material-ui/core/styles";
import { useUser } from "context/UserContext";
import NavItem from "corecomponents/AuthNavbar/NavLink";
import RegisterButton from "corecomponents/AuthNavbar/RegisterButton";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import cx from "classnames";

const styles = {
  root: {
    margin: 0,
    marginRight: "-15px",
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    display: "flex",
    alignItems: "center"
  },
  drawer: {
    flexDirection: "column"
  }
};

const useStyles = makeStyles(styles);

export default function NavLinkList({ drawer }) {
  const { user, logOut } = useUser();
  const classes = useStyles();

  return (
    <List
      className={cx(classes.root, {
        [classes.drawer]: drawer
      })}
    >
      <NavItem label={"Home"} route="/" icon={<Home />} />
      {user && (
        <NavItem
          label={"Transacciones"}
          route="/transactions"
          icon={<MonetizationOnIcon />}
        />
      )}
      {user ? (
        <NavItem
          onClick={() => logOut()}
          label={"Cerrar sesión"}
          route="/logout"
          icon={<ExitToAppIcon />}
        />
      ) : (
        <NavItem
          label={"Ingresar"}
          route="/auth/login"
          icon={<Fingerprint />}
        />
      )}
      <RegisterButton />
    </List>
  );
}
