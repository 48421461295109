import React, { useState } from "react";
import { useForm } from "react-hook-form";
import api from "api/api";
import { Grid } from "@material-ui/core";
import Button from "components/CustomButton/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { useUser } from "context/UserContext";
import { useHistory } from "react-router-dom";
import Form from "components/Form/Form";

const bankTypeOptions = [
  { value: 1, label: "Ahorro" },
  { value: 2, label: "Corriente" }
];

const bankNameOptions = [
  { value: 1, label: "BANCAMIA S.A." },
  { value: 2, label: "BANCO AGRARIO" },
  { value: 3, label: "BANCO AV VILLAS" },
  { value: 4, label: "BANCO CAJA SOCIAL BCSC SA" },
  { value: 5, label: "BANCO COMPARTIR S.A" },
  { value: 6, label: "BANCO COOPERATIVO COOPCENTRAL" },
  { value: 7, label: "BANCO DAVIVIENDA SA" },
  { value: 8, label: "BANCO DE BOGOTA" },
  { value: 9, label: "BANCO DE OCCIDENTE" },
  { value: 10, label: "BANCO FALABELLA S.A." },
  { value: 11, label: "BANCO FINANDINA S.A." },
  { value: 12, label: "BANCO GNB SUDAMERIS" },
  { value: 13, label: "BANCO MUNDO MUJER" },
  { value: 14, label: "BANCO PICHINCHA" },
  { value: 15, label: "BANCO POPULAR" },
  { value: 16, label: "BANCO PROCREDIT COLOMBIA" },
  { value: 17, label: "BANCO SANTANDER DE NEGOCIOS COLOMBIA S.A" },
  { value: 18, label: "BANCO SERFINANZA S.A" },
  { value: 19, label: "BANCO W S.A." },
  { value: 20, label: "BANCOLDEX S.A." },
  { value: 21, label: "BANCOLOMBIA" },
  { value: 22, label: "BANCOOMEVA" },
  { value: 23, label: "BBVA COLOMBIA" },
  { value: 24, label: "CITIBANK" },
  { value: 25, label: "COLTEFINANCIERA S.A." },
  { value: 26, label: "CONFIAR COOPERATIVA FINANCIERA" },
  { value: 27, label: "COOPERATIVA FINANCIERA DE ANTIOQUIA" },
  { value: 28, label: "COOTRAFA COOPERATIVA FINANCIERA" },
  { value: 29, label: "DAVIPLATA" },
  { value: 30, label: "FINANCIERA JURISCOOP S.A. COMPAÑIA DE FINANCIAMIENTO" },
  { value: 31, label: "ITAU" },
  { value: 32, label: "ITAU antes Corpbanca" },
  { value: 33, label: "NEQUI" },
  { value: 34, label: "RAPPIPAY" },
  { value: 35, label: "SCOTIABANK COLPATRIA S.A" }
];

export default function FinancialInformation({ onClose }) {
  const { register, handleSubmit } = useForm();

  const { user } = useUser();
  const [bankType, setBankType] = useState(bankTypeOptions[0].value);
  const [bankName, setBankName] = useState(bankNameOptions[0].value);
  const history = useHistory();

  const onSubmit = async data => {
    const { bank_account_number } = data;

    const response = await api.post(
      "/onboarding/bankinformation/",
      {
        bank_account_number,
        bank_account_type: bankType,
        bank: bankName,
        customer: user.user.customer.uuid
      },
      {
        headers: {
          Authorization: `Token ${user.key}`
        }
      }
    );

    if (onClose) {
      onClose();
    } else if (response.status === 201) {
      console.log(response);
      history.push("/auth/awaitingverification");
    }
  };

  return (
    <Grid container justify="center" style={{ height: "100%" }}>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img
            src={"https://img.icons8.com/nolan/128/bank-building.png"}
            alt="BANK_INFORMATION"
            style={{
              width: "30%"
            }}
          />
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <CustomInput
                labelText="No. de cuenta bancaria"
                inputRef={register}
                name={"bank_account_number"}
                icon={<AccountBalanceWalletIcon />}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInput
                labelText="Tipo"
                inputRef={register}
                select
                options={bankTypeOptions}
                name={"bank_account_type"}
                formControlProps={{
                  fullWidth: true
                }}
                selectValue={bankType}
                setSelectValue={setBankType}
              />
            </Grid>
          </Grid>
          <CustomInput
            labelText="Nombre del banco"
            inputRef={register}
            name={"bank_name"}
            formControlProps={{
              fullWidth: true
            }}
            select
            options={bankNameOptions}
            selectValue={bankName}
            setSelectValue={setBankName}
            icon={<AccountBalanceIcon />}
          />
          <Button
            style={{ width: "100%" }}
            color="rose"
            onClick={handleSubmit(onSubmit)}
          >
            Agregar información financiera
          </Button>
        </Form>
      </Grid>
    </Grid>
  );
}
